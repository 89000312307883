import React from 'react'
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Layout from '../components/Layout/layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class NotFoundPage extends React.Component {
    render(){
        return <Layout >
            <Container className='bg-cream py-5' fluid>
                <Container className='py-5'>
                    <Row className='d-flex justify-content-center align-items-center py-5 '>
                        <Col as='section' lg={12}>
                            <h1>Page not found</h1>
                            <p>The page you were looking for has been removed or relocated</p>
                            <Link to='/' className='btn btn-primary'>Go Home</Link>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Layout>
    }
}

export default NotFoundPage